<template>
	<Component
		:is="tag || (href ? 'a' : 'router-link')"
		class="zyro-ribbon"
		:class="{ [`zyro-ribbon--${theme}`]: theme }"
		:href="href"
		:to="to || undefined"
		target="target"
		v-on="$listeners"
	>
		<slot />
		<ZyroButton
			v-if="buttonText"
			class="zyro-ribbon__button"
			theme="primary"
			color="white"
		>
			{{ buttonText }}
		</ZyroButton>
	</Component>
</template>

<script>
import ZyroButton from '@/components/global/ZyroButton.vue';

export default {
	components: { ZyroButton },
	props: {
		to: {
			type: [
				String,
				Object,
			],
			default: () => ({}),
		},
		href: {
			type: String,
			default: null,
		},
		target: {
			type: String,
			default: '_blank',
		},
		buttonText: {
			type: String,
			default: null,
		},
		theme: {
			type: String,
			validator: (value) => [
				'plump-purple',
				'blue',
			].includes(value),
			default: 'plump-purple',
		},
		tag: {
			type: String,
			default: undefined,
		},
	},
};
</script>

<style lang="scss" scoped>
.zyro-ribbon {
	$this: &;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 24px;
	overflow: hidden;
	color: $light;
	text-decoration: none;

	// Only ribbon bg and button text color changes. Button background is white for all.
	@mixin generateColors($bem-parent, $color-class-name, $color) {
		&--#{$color-class-name} {
			background: $color;

			#{$bem-parent}__button {
				&:not(.s) {
					color: $color;
				}
			}
		}
	}

	$themes-map: (
		'plump-purple': $primary,
		'blue': $accent-two
	);

	@each $theme, $color in $themes-map {
		@include generateColors($this, $theme, $color);
	}

	&__button {
		margin-left: 8px;
	}

	// All buttons are white
	&:hover #{$this}__button,
	#{$this}__button:hover {
		&:not(.s) {
			background-color: rgba($light, 0.87);
			border-color: transparent;
		}
	}

	&:focus {
		outline: 0;
	}

	&:focus #{$this}__button,
	#{$this}__button:focus {
		&:not(.s) {
			background-color: rgba($light, 0.87);
			border-color: $light;
		}
	}

	@include zyro-media($media-tablet) {
		justify-content: space-between;
		padding: 16px 24px;
	}
}
</style>
