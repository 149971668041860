<template>
	<div
		ref="manager"
		class="store-manager"
	>
		<div
			ref="header"
			class="store-manager__header"
		>
			<BuilderHeaderStoreManager :is-from-builder="isFromBuilder" />
			<ZyroRibbon
				v-if="!isSiteWithEcwid"
				class="store-manager__ribbon"
				theme="blue"
				tag="div"
				:button-text="$t('siteSettings.ribbon.addStoreButton')"
				@click="addStore"
			>
				{{ $t('siteSettings.ribbon.addStoreText') }}
			</ZyroRibbon>
		</div>
		<iframe
			frameborder="0"
			seamless
			width="100%"
			:height="iframeHeight"
			:src="storeManagerUrl"
		/>
	</div>
</template>

<script>
import { debounce } from 'lodash.debounce';

import BuilderHeaderStoreManager from '@/components/builder-view/headers/BuilderHeaderStoreManager.vue';
import ZyroRibbon from '@/components/ui/ZyroRibbon.vue';
import { BUILDER_ROUTE } from '@/router';
import { ADD_ECWID_BLOCK_MODAL } from '@/store/builder/constants/modals';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';
import {
	mapStateOnlineStore,
	mapGettersOnlineStore,
	mapActionsOnlineStore,
	GET_STORE_MANAGER_URL,
} from '@/store/builder/onlineStore';

const INITIAL_HEADER_HEIGHT = 50;
const RESIZE_DEBOUNCE = 1000;

export default {
	components: {
		BuilderHeaderStoreManager,
		ZyroRibbon,
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => vm.setIsFromBuilder(from.name === BUILDER_ROUTE));
	},
	data() {
		return {
			resizeObserver: null,
			managerHeight: null,
			iframeHeight: window.innerHeight - INITIAL_HEADER_HEIGHT,
			isFromBuilder: false,
		};
	},
	computed: {
		...mapStateOnlineStore({ storeManagerUrl: 'storeManagerUrl' }),
		...mapGettersOnlineStore({ isSiteWithEcwid: 'isSiteWithEcwid' }),
	},
	mounted() {
		if (!this.storeManagerUrl) {
			this.initOnlineStore();
		}

		this.resizeObserver = new ResizeObserver(debounce(this.calcManagerHeight, RESIZE_DEBOUNCE));
		this.resizeObserver.observe(this.$refs.manager);
	},
	beforeDestroy() {
		this.resizeObserver.unobserve(this.$refs.manager);
	},
	methods: {
		...mapActionsGui({ openModal: OPEN_MODAL }),
		...mapActionsOnlineStore({ getStoreManagerUrl: GET_STORE_MANAGER_URL }),
		setIsFromBuilder(isFromBuilder) {
			this.isFromBuilder = isFromBuilder;
		},
		async initOnlineStore() {
			try {
				await this.getStoreManagerUrl();
				this.calcIframeHeight();
			} catch {
				// TODO: handle some errors
			}
		},
		calcIframeHeight() {
			this.managerHeight = window.innerHeight;
			this.iframeHeight = this.managerHeight - this.$refs.header.clientHeight;
		},
		calcManagerHeight(entries) {
			if (entries[0].contentRect.height !== this.managerHeight) {
				this.calcIframeHeight();
			}
		},
		addStore() {
			this.$router.push({ name: BUILDER_ROUTE });
			this.openModal({
				name: ADD_ECWID_BLOCK_MODAL,
				settings: { hideSecondaryButton: true },
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.store-manager {
	height: 100vh;
	overflow: hidden;

	&__ribbon {
		position: relative;
	}
}
</style>
