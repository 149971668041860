<template>
	<div class="builder-header">
		<ZyroButton
			v-qa="`builder-storemanager-header-btn-${backText}`"
			class="builder-header__back"
			theme="header"
			color="white"
			icon-left="chevron-left-small"
			@click="handleBackClick"
		>
			{{ backText }}
		</ZyroButton>

		<div class="builder-header__title">
			<h3 class="z-subheading">
				{{ $t('builder.storeManager.title') }}
			</h3>
		</div>
	</div>
</template>

<script>
import {
	mapActions,
	mapState,
} from 'vuex';

import {
	BUILDER_ROUTE,
	ONLINE_STORE_SETTINGS_ROUTE,
} from '@/router';

export default {
	props: {
		isFromBuilder: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('pages', ['currentPageId']),
		backText() {
			return this.isFromBuilder
				? this.$t('builder.headerPreview.backToEditor')
				: this.$t('builder.storeManager.backToSiteSettings');
		},
	},
	methods: {
		...mapActions('pages', ['setCurrentPage']),
		handleBackClick() {
			if (!this.isFromBuilder) {
				this.$router.push({ name: ONLINE_STORE_SETTINGS_ROUTE });
			} else {
				this.$router.push({ name: BUILDER_ROUTE });
				this.setCurrentPage({ pageId: this.currentPageId });
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import "./BuilderHeader";

.builder-header {
	position: relative;
	justify-content: center;
	min-height: 50px;

	@include mobile-view {
		justify-content: flex-end;
	}

	&__back {
		position: absolute;
		top: 0;
		left: 0;
	}
}
</style>
